import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/project/project/model",
    // 层级选择器
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    //添加属性
    addAttribute(query: any) {
        return requests.post(this.modulesUrl + "/addAttribute", query);
    },
    //修改属性
    modAttribute(query: any) {
        return requests.post(this.modulesUrl + "/modAttribute", query);
    },
    //切换属性状态
    statusAttribute(query: any) {
        return requests.post(this.modulesUrl + "/statusAttribute", query);
    },
    //属性排序保存
    sortAttributeSave(query: any) {
        return requests.post(this.modulesUrl + "/sortAttributeSave", query);
    },
};
